import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import styled from "@emotion/styled";

import { Container } from "../styles/elements";

const PostHeader = styled.div`
  position: relative;
  height: 400px;
  background-image: ${(props) => `url('${props.headerImg}')`};
`;

const PostHeaderContent = styled(Container)`
  background-image: ${(props) => `url('${props.headerImg}')`};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 40px;
  height: 100%;
  filter: none;
`;

const PostDataContainer = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
`;

const PostTitle = styled.h1`
  font-family: "Tiempos";
  font-weight: 500;
  color: white !important;
`;

const PostMetadata = styled.div`
  border-bottom: 2px solid #eee;
  padding: 15px 0;
  margin: 0 50px 60px;
`;

const PostAuthor = styled.span``;

const PostContainer = styled(Container)`
  background: white;
  margin-top: 40px;
  border: 1px solid #eee;
`;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  headerImg,
  author,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <PostContainer>
      <PostHeader headerImg={headerImg}>
        <PostHeaderContent headerImg={headerImg}>
          <PostDataContainer>
            <PostTitle className="title is-size-2 is-bold-light">
              {title}
            </PostTitle>
            {author && <PostAuthor>by {author}</PostAuthor>}
          </PostDataContainer>
        </PostHeaderContent>
      </PostHeader>
      <section className="section">
        {helmet || ""}
        <div className="content">
          <PostMetadata>
            <PostAuthor></PostAuthor>
          </PostMetadata>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </PostContainer>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        headerImg={post.frontmatter.headerImg.publicURL}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        headerImg {
          publicURL
        }
        author
      }
    }
  }
`;
